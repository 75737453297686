import React from 'react'

export const PrimaryLogo: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1027.43 200.44">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M172,139.18C149.74,201.61,71,222.53,26,171-27.12,114,4.7.25,89.31,0,157.19-.6,196.31,80.61,172,139.18ZM138,43c-5.57-20.7-12.54-34.14-30.45-39.74C91.16-.35,69.68-.91,56.7,11.59,31.12,39.17,35.25,96.19,37,135.28c2.39,25.79,8.06,53.66,34.36,61.84,21.63,5.72,48.93,2.08,60-19.94C148.28,142.55,144.78,77.85,138,43Z" />
        <path d="M367.52,195.91c-4.27-2.31,24.76-.58,24.26-1.08,4.66,1.2,0-186.17,1.61-187.28-.84.61-60.76,192.46-62.51,192.13-1.06.09-71.12-192-71.68-192.4,1.57,1.15-3.05,188.72,1.61,187.55h24.26c.72-.07.72,1.15,0,1.08,0-1.19-56.67,2-53.09-1.08,1-.55,26.58,1.31,25.87-1.08-1.57-1.14,3-189.27-1.61-188.09H232c-3.69-2.91,58.35-.16,58.2-1.08q2.16,0,4.32,4.85l51.74,138.78,45-138.78q1.33-4.84,3.5-4.85c.55.56,58.64-1,58.47.54a.47.47,0,0,1-.53.54h-24c-5.42-1.66-.05,187-1.89,188.09-1.07,2.57,25.07.39,25.88,1.08C456.38,197.82,367.38,194.94,367.52,195.91Z" />
        <path d="M613.28,195.64c-4.09-2.37,26.26-.53,25.87-1.08,4.78,1.22-22.83-64.59-21.83-65.21H554.54c-1,6.36-25.75,61.77-22.1,65.21h24.79a.48.48,0,0,1,.54.54c.15,1.56-50.91,0-51.47.54-.72.07-.72-1.15,0-1.08,2.52-.65,22.5,1.75,23.31-1.75.63-1.17,1.93-4.35,3.91-9.57C534.87,183.19,599-2.54,601.69.81c2.14,1.17,72.38,195.3,76.8,193.75H701.4C705.17,197.5,613.08,194.71,613.28,195.64ZM586.06,43.12l-31.25,85.15h62Z" />
        <path d="M937.14,197.66c-34.39,11.49-60.25-14.06-58.47-49.72V130.43c1-35.94-39.18-34.41-65.48-33.69v96.74c0,.72.62,1.08,1.88,1.08h28c.72-.07.72,1.16,0,1.08,0-1.2-92.46,2.07-88.93-1.08,1-.46,26.53,1.26,25.87-1.08-1.56-1.14,3-189-1.61-187.82H754.17c-3.74-3,84.79-.11,84.62-1.08a95.75,95.75,0,0,1,27.48,3.64c64.08,20.09,41.08,89.83-20.48,88,26.39,0,53.29,7.13,63.6,31.67,5.33,11.41,3.49,32.31,3.77,44.86-.8,15.81,10.66,31.59,26.54,22.64a32.13,32.13,0,0,0,7.41-5.39C950.41,190.57,939.11,197.09,937.14,197.66Zm-76-181.22c-8.87-11.61-31.1-11.23-46.08-10.78-4.79-1.1-.49,88.44-1.88,90,15.32-1.07,39.87,4,50.66-8.63C875.38,70.64,873.35,29.26,861.15,16.44Z" />
        <path d="M1023.41,195c-12.94,13.43-31.53-7.76-19-20.22C1017.62,161.32,1035.54,182.47,1023.41,195Z" />
      </g>
    </g>
  </svg>
)

export const SecondaryLogo: React.FC = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 332.6 371.2"
    enableBackground="new 0 0 332.6 371.2"
    xmlSpace="preserve"
  >
    <path
      d="M319.2,258.3c-41.4,115.8-187.5,154.6-271,59C-50.3,211.4,8.7,0.5,165.7,0C291.7-1.1,364.3,149.6,319.2,258.3z M256,79.8
	C240.5,9.5,195.5-7.6,132.5,6C48.1,27.9,62,225.7,76,297c7.7,32,25.1,59.5,56.5,68.8c40.1,10.6,90.8,3.9,111.3-37
	C274.7,268.3,268.7,141.1,256,79.8z"
    />
  </svg>
)
