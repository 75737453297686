import { MotionStyle, Variants, motion } from 'framer-motion'
import React, { SVGProps } from 'react'

interface LunaLogoProps extends SVGProps<SVGSVGElement> {
  variants?: Variants
  motionStyle?: MotionStyle
}

export const LunaLogo: React.FC<LunaLogoProps> = ({ variants, motionStyle, ...props }: LunaLogoProps) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.846 171.296">
    <motion.path
      custom={1}
      variants={variants}
      d="M.92,68V.8H11.288V58.016H50.264V68Zm98.505.576A33.019,33.019,0,0,1,83.3,64.736,28.689,28.689,0,0,1,72.449,53.984a30.744,30.744,0,0,1-3.84-15.36V.7H78.977V38.048A19.852,19.852,0,0,0,89.057,55.9,21.125,21.125,0,0,0,99.425,58.5,21.71,21.71,0,0,0,110.081,55.9a19.537,19.537,0,0,0,7.488-7.3,20.339,20.339,0,0,0,2.784-10.56V.7h9.888v37.92a30.153,30.153,0,0,1-3.936,15.36,27.814,27.814,0,0,1-10.848,10.752A32.469,32.469,0,0,1,99.425,68.576ZM.92,172V104.8h9.888l48.576,55.008-.96.864q-.48-2.688-.768-5.76t-.576-6.432q-.192-3.456-.384-7.008l-.192-7.3V104.8H66.872V172H56.792l-47.9-52.992.48-1.344q.288,3.552.576,6.72.288,3.072.48,5.952.288,2.784.48,5.184t.288,4.608q.1,2.112.1,3.936V172Zm83,0,29.76-67.2H125.1L154.766,172H143.534l-19.392-43.776a36.485,36.485,0,0,1-1.536-3.36q-.768-2.112-1.728-4.416-.864-2.4-1.728-4.608-.768-2.3-1.344-3.936l2.688-.1q-.768,2.016-1.632,4.224-.768,2.208-1.632,4.512-.864,2.208-1.728,4.32-.864,2.016-1.632,3.744L94.67,172Zm13.056-16.128,3.936-9.984h35.808l2.88,9.984Z"
      transform="translate(-0.92 -0.704)"
      style={motionStyle}
    />
  </svg>
)
