const variants = {
  button: {
    rest: {
      transition: {
        staggerChildren: 0.2
      }
    },
    hover: {
      transition: {
        staggerChildren: 0.2
      }
    }
  },
  letter: {
    rest: {
      y: 50
    },
    hover: {
      y: 0
    }
  }
}

export default variants
