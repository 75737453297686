exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-design-danos-tsx": () => import("./../../../src/pages/work/design/danos.tsx" /* webpackChunkName: "component---src-pages-work-design-danos-tsx" */),
  "component---src-pages-work-design-luna-tsx": () => import("./../../../src/pages/work/design/luna.tsx" /* webpackChunkName: "component---src-pages-work-design-luna-tsx" */),
  "component---src-pages-work-design-pchunt-tsx": () => import("./../../../src/pages/work/design/pchunt.tsx" /* webpackChunkName: "component---src-pages-work-design-pchunt-tsx" */),
  "component---src-pages-work-design-tsx": () => import("./../../../src/pages/work/design.tsx" /* webpackChunkName: "component---src-pages-work-design-tsx" */),
  "component---src-pages-work-photography-automotive-tsx": () => import("./../../../src/pages/work/photography/automotive.tsx" /* webpackChunkName: "component---src-pages-work-photography-automotive-tsx" */),
  "component---src-pages-work-photography-documentary-tsx": () => import("./../../../src/pages/work/photography/documentary.tsx" /* webpackChunkName: "component---src-pages-work-photography-documentary-tsx" */),
  "component---src-pages-work-photography-nude-tsx": () => import("./../../../src/pages/work/photography/nude.tsx" /* webpackChunkName: "component---src-pages-work-photography-nude-tsx" */),
  "component---src-pages-work-photography-portrait-tsx": () => import("./../../../src/pages/work/photography/portrait.tsx" /* webpackChunkName: "component---src-pages-work-photography-portrait-tsx" */),
  "component---src-pages-work-photography-tsx": () => import("./../../../src/pages/work/photography.tsx" /* webpackChunkName: "component---src-pages-work-photography-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

