const variants = {
  handsMobile: {
    hidden: { y: '50%' },
    inView: {
      y: 0,
      transition: {
        ease: 'easeOut',
        duration: 0.5
      }
    }
  }
}

export default variants
