const variants = {
  container: {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  }
}

export default variants
