import React, { SVGProps } from 'react'

export const PCHuntLogoSecondary: React.FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 385.72">
    <path
      d="M300,357.15h0c-15.71,0-30-10-35.71-25.72,0-1.43,0-2.85-1.43-2.85H135.72c0,1.42,0,2.85-1.43,2.85C130,347.15,115.72,357.15,100,357.15h0c-8.57,0-14.28,5.71-14.28,14.28s5.71,14.29,14.28,14.29H300c8.57,0,14.29-5.72,14.29-14.29S308.54,357.15,300,357.15Z"
      transform="translate(0 0)"
    />
    <path
      d="M357.14,0H42.86C18.57,0,0,18.58,0,42.86V257.15C0,281.43,18.57,300,42.86,300H357.14c24.29,0,42.86-18.57,42.86-42.85V42.9C400,18.58,381.43,0,357.14,0ZM137.28,186.71a3.48,3.48,0,0,1-3.45,3.46H107.46a3.5,3.5,0,0,1-3.46-3.46V159.45H78.4v27.26A3.48,3.48,0,0,1,75,190.17H48.58a3.42,3.42,0,0,1-2.43-1,3.24,3.24,0,0,1-1-2.5V104a3.5,3.5,0,0,1,3.46-3.46H75A3.48,3.48,0,0,1,78.4,104v26.24H104V104a3.5,3.5,0,0,1,3.46-3.46h26.37a3.48,3.48,0,0,1,3.45,3.46Zm106.24,0a3.48,3.48,0,0,1-3.46,3.46h-20.6a6.2,6.2,0,0,1-5.12-2.69L186.43,151.9v34.81a3.48,3.48,0,0,1-3.45,3.46H159.17a3.5,3.5,0,0,1-3.46-3.46V104a3.5,3.5,0,0,1,3.46-3.46H179.9a6,6,0,0,1,5,2.56l27.9,38.53V104a3.5,3.5,0,0,1,3.46-3.46h23.8a3.48,3.48,0,0,1,3.46,3.46Zm112.9-62.08a3.37,3.37,0,0,1-1,2.44,3.32,3.32,0,0,1-2.43,1H325.44v58.62a3.48,3.48,0,0,1-3.46,3.46H295.62a3.48,3.48,0,0,1-3.46-3.46V128.09H264.64a3.5,3.5,0,0,1-3.46-3.46V104a3.5,3.5,0,0,1,3.46-3.46H353a3.32,3.32,0,0,1,2.43,1,3.37,3.37,0,0,1,1,2.44Z"
      transform="translate(0 0)"
    />
  </svg>
)
